/* General Styling */
body {
  margin: 0;
  font-family: 'Sora', sans-serif !important;
  background-color: #121212; /* Background */
  color: #ACDF75 !important; /* Accent Text */
}

/* Chat Container */
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  max-width: 100%;
  margin: auto;
  border: 1px solid #ACDF75; /* Accent border for container */
  border-radius: 8px;
  overflow: hidden;
  background-color: #1C1C1C; /* Card background */
}

/* Chat Header */
.chat-header {
  padding: 1rem;
  background-color: #1C1C1C; /* Card background */
  text-align: center;
  color: #ACDF75; /* Accent Text */
  font-size: 1.5rem;
  font-weight: bold;
}

/* Chat Messages */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #121212; /* Background */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
}

.message {
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #1C1C1C; /* Card background */
  color: #ACDF75; /* Accent Text */
  word-wrap: break-word;
  white-space: pre-wrap; /* Preserve newlines */
}

.message.streaming {
  background-color: #ACDF75; /* Accent for streaming messages */
  color: #121212; /* Dark text for contrast */
  font-style: italic;
}

/* Chat Input Section */
.chat-input {
  display: flex;
  padding: 1rem;
  background-color: #1C1C1C; /* Card background */
  gap: 0.5rem;
  align-items: center;
}

.chat-input input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 8px;
  background-color: #121212; /* Background for input */
  color: #ACDF75; /* Accent Text */
  font-size: 1rem;
  outline: none;
}

.chat-input button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background-color: #ACDF75; /* Main Button Accent */
  color: #121212; /* Dark text */
  font-size: 1rem;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #8ECB60; /* Slightly darker green for hover */
}

/* Scrollbar Styling */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ACDF75; /* Green scrollbar thumb */
  border-radius: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #121212; /* Match chat background */
}

/* React-Select Custom Styles */
.react-select-container {
  width: 100%;
  margin: 15px 0;
}

.react-select__control {
  background-color: #1C1C1C;
  border: 1px solid #ACDF75;
  color: #ACDF75;
  border-radius: 8px;
  padding: 5px;
}

.react-select__control:hover {
  border-color: #8ECB60;
}

.react-select__value-container {
  padding: 5px;
}

.react-select__single-value {
  color: #ACDF75; /* Accent Text */
}

.react-select__input {
  color: #ACDF75; /* Input text color */
}

.react-select__menu {
  background-color: #1C1C1C;
  border-radius: 8px;
  border: 1px solid #ACDF75;
  overflow: hidden;
}

.react-select__option {
  background-color: #1C1C1C;
  color: #ACDF75; /* Accent Text */
  padding: 10px;
  cursor: pointer;
}

.react-select__option:hover {
  background-color: #8ECB60; /* Hover Green */
  color: #121212; /* Dark text */
}

.react-select__option--is-focused {
  background-color: #ACDF75; /* Focused option green */
  color: #121212; /* Dark text */
}

.react-select__option--is-selected {
  background-color: #8ECB60; /* Selected option slightly darker */
  color: #121212; /* Dark text */
}

/* Table Container */
.table-container {
  margin: 20px auto;
  max-width: 800px;
  border: 1px solid #ACDF75; /* Accent border */
  border-radius: 8px;
  background-color: #1C1C1C; /* Card background */
  overflow: hidden;
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #121212; /* Background */
  color: #ACDF75; /* Accent Text */
}

/* Table Header */
.table th {
  background-color: #1C1C1C; /* Card background */
  color: #ACDF75; /* Accent Text */
  font-weight: bold;
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #ACDF75; /* Accent border for header */
}

/* Table Rows */
.table td {
  padding: 15px;
  border-bottom: 1px solid #ACDF75; /* Accent border between rows */
}

/* Table Row Hover Effect */
.table tr:hover {
  background-color: #1C1C1C; /* Card background for hover */
  color: #8ECB60; /* Slightly lighter green */
}

/* Alternate Row Colors */
.table tr:nth-child(odd) {
  background-color: #121212; /* Dark background */
}

.table tr:nth-child(even) {
  background-color: #1C1C1C; /* Slightly lighter background */
}

/* Table Footer */
.table tfoot td {
  background-color: #1C1C1C; /* Card background */
  color: #ACDF75; /* Accent Text */
  font-weight: bold;
  padding: 15px;
  text-align: center;
  border-top: 2px solid #ACDF75; /* Accent border for footer */
}

/* Scrollbar Styling for Table Container */
.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ACDF75; /* Green scrollbar thumb */
  border-radius: 8px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #121212; /* Match container background */
}

/* General Tag Styling */
.tag-container {
  display: flex;
  flex-wrap: wrap; /* Allows tags to wrap to the next line if needed */
  gap: 10px; /* Space between tags */
  padding: 10px;
  background-color: #1C1C1C; /* Match your card background */
  border: 1px solid #ACDF75; /* Accent border */
  border-radius: 8px;
  max-width: 800px;
  margin: 20px auto;
}

.badge {
  background-color: #8ECB60 !important;
  color:#1C1C1C !important;
}

.btn-primary {
  background-color: #8ECB60 !important;
  color:#1C1C1C !important;
  border-color: #8ECB60;
}

.user-message {
  background-color: #8ECB60 !important;
  color: #1C1C1C !important;
}

.ai-message {
  background-color: #1C1C1C !important;
  color: #ACDF75 !important;
}