.card {
    background-color:#354F52 !important;
    border-width: 0px;
    color:white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 30px;
    border: solid #84A98C 1px !important;
}

.template-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the shadow */
}

.template-card:hover {
    border: solid 1px #688BEF;
    box-shadow: 0 0 20px #688cef80; /* Adjust the size and color as needed */
}